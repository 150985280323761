'use client';

import ErrorPageLight from '@/src/components/Error/ErrorPageLight';

export default function GlobalError({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  return <ErrorPageLight error={error} reset={reset} />;
}
