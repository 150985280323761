'use client';

import { poppins } from '@/src/components/font';
import Image from 'next/image';
import Link from 'next/link';
import { useEffect } from 'react';

export default function ErrorPageLight({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  useEffect(() => {
    // Log the error to an error reporting service
    console.error(error);
  }, [error]);

  return (
    <html lang="en">
      <body className={`${poppins.className} antialiased`}>
        <div className="h-screen w-screen flex flex-col items-center justify-center ic-disable-resp-font">
          <Link href="/" className="flex absolute top-[4rem] left-[4rem]">
            <Image
              src="/images/ic-logo.png"
              alt="Interview Cube"
              height={72 / 2}
              width={163 / 2}
            />
          </Link>
          <Image
            src="/images/landing-page/lp-error.svg"
            width={402.61}
            height={454.12}
            alt="Error"
            className="w-[300px] h-auto mb-[2rem]"
          />
          <h1 className="font-semibold text-4xl text-[#3A3A3A] mb-[1rem]">
            Error
          </h1>
          <h2 className="font-normal text-xl text-[#3A3A3A] mb-[2rem]">
            Something went wrong!
          </h2>
          <button
            onClick={() => reset()}
            className="bg-[#274CE0] text-white px-[2.25rem] py-[0.875rem] rounded-[5.62rem]"
          >
            Try again
          </button>
        </div>
      </body>
    </html>
  );
}
